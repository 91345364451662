<template>
  <div>
    <div style="display: none;">
      <el-button type="primary" @click="dialogFormVisible = true; detail={}">新增</el-button>
    </div>
    <el-table :data="list" border :max-height="maxHeight" style="margin-top: 20px" v-loading="loading">
      <el-table-column prop="id" label="规则编号"></el-table-column>
      <el-table-column prop="pointsAmount" label="积分数值"></el-table-column>
      <el-table-column prop="creditTypeDes" label="规则说明"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="totalRows" :current-page="page" :page-size="20" style="margin-top: 20px"> </el-pagination>
    <el-dialog title="添加/编辑积分规则" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="规则类型" :label-width="formLabelWidth" v-if="!detail.id">
          <el-select v-model="form.ruleType" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="积分值" :label-width="formLabelWidth">
          <el-input v-model="form.creditPoints" autocomplete="off" placeholder="请输入积分值"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CreditRuleApi from '@/request/CreditRule.js';
export default {
  data() {
    return {
      maxHeight: document.documentElement.clientHeight - 250,
      list: [],
      page: 1,
      detail: {},
      totalRows: 0,
      loading: false,
      dialogFormVisible: false,
      form: {
        ruleType: '',
        creditPoints: '',
      },
      options: [
        {
          value: 'CANT_USE',
          label: '不能使用积分',
        },
        {
          value: 'USER_DEFINED',
          label: '自定义',
        },
        {
          value: 'FIXED_VALUE',
          label: '固定值',
        },
        {
          value: 'BK_INCREASE',
          label: '后台添加',
        },
        {
          value: 'BK_DECREASE',
          label: '后台扣除',
        },
        {
          value: 'PAY_ORDER_GIFT_POINTS',
          label: '购买赠送',
        },
        {
          value: 'SHARE_BUY_GIFT_POINTS',
          label: '分享后购买赠送',
        },
      ],
      formLabelWidth: '120px',
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      CreditRuleApi.creditRuleList()
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.list = res.data;
            this.totalRows = res.totalRows;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleChangePage(val) {
      this.page = val;
      this.loadData();
    },
    async handleConfirm() {
      this.dialogFormVisible = false;
      if (this.detail.id) {
        CreditRuleApi.updateCreditRule({
          ...this.detail,
          pointsAmount: +this.form.creditPoints,
          creditType: this.form.ruleType,
        }).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.loadData();
          }
        });
      } else {
        const exist = this.list.find(item => item.ruleType === this.form.ruleType);
        if (exist) {
          this.$message.warning("类型已存在, 请替换!")
          return;
        }
        CreditRuleApi.createCreditRule({
          creditPoints: +this.form.creditPoints,
          creditType: this.form.ruleType,
        }).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.loadData();
          }
        });
      }
    },
    handleEdit(row) {
      this.detail = row;
      this.dialogFormVisible = true;
      this.form.ruleType = row.creditType;
      this.form.creditPoints = row.creditPoints;
    },
  },
};
</script>

<style></style>
