import htttp from './http';

class CreditRuleApi {
  static creditRuleList() {
    return htttp.post('/zp-mobile/v1/backend/points/rule/list');
  }

  static createCreditRule(data) {
    return htttp.post('/zp-mobile/v1/backend/points/rule/create', data);
  }

  static updateCreditRule(data) {
    return htttp.post('/zp-mobile/v1/backend/points/rule/update', data);
  }

  static deleteCreditRule(data) {
    return htttp.post('/zp-mobile/v1/backend/points/rule/delete', data);
  }

  static sendPoints(data) {
    return htttp.post('/zp-mobile/v1/user-info/increase/points', data);
  }
}

export default CreditRuleApi;
